/***
 *
 * Controller class for user.
 * @file SiteLogo.js
 * @description SiteLogo component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
import {
  useSelector
} from 'react-redux';
import { appDefaults } from '../../config';
import { siteTypes } from '../../constants';
import { isServer } from '../../helpers/server.helper';
import ImageWrapper from '../ImageWrapper';
// import PropTypes from 'prop-types';
import './SiteLogo.scss';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';

const SiteLogo = ({ className }) => {
  const app = useSelector(state => state?.app);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [logo, setLogo] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setLogo(appDefaults.host + app?.meetingPlace?.logo);
  }, [app?.meetingPlace?.logo, app?.selectedLanguage]);

  const checkFocus = (e) => {
    if (e.type === "focus") {
      setIsFocused(true);
    } else if (e.type === "blur") {
      setIsFocused(false)
    }
  };

  const onClick = () => ((app?.meetingPlace?.url && app?.site?.type !== siteTypes.MEETINGROOM && !isServer) ? window?.location.assign(app?.meetingPlace?.url) : null)

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  }

  return (
    <div tabIndex={0} onFocus={checkFocus} onBlur={checkFocus} onKeyDown={onEnter}
      className={`${(app?.meetingPlace?.url && app?.site?.type !== siteTypes.MEETINGROOM) ? "activeLogo" : ''} site-logo-container`}
      data-testid="SiteLogo" onClick={onClick}>
      {logo && app?.meetingPlace ? (
        <ImageWrapper className={`${className} ${isFocused ? 'site-logo-focus' : ''}`} src={logo} alt={app?.meetingPlace?.name} height={matches ? app?.meetingPlace?.logoHeight : 51} width={matches ? app?.meetingPlace?.logoWidth : ((51 * 100) / app?.meetingPlace?.logoWidth)} />
      ) : (
        <Skeleton variant='rectangular' width={68} height={68} animation='pulse' />
      )}
    </div>
  )
};

SiteLogo.propTypes = {};

SiteLogo.defaultProps = {};

export default SiteLogo;
