/**
 * 
 * @name = Private Routes
 * @description = Private Routes vary based on the Apps, For example, 
 * Dashboard, User Profile, App Settings, Home etc. In simple words, 
 * These routes can be accessed only after login.
 * 
 * @author = Naveen Kumar
 * @since = 07 Oct, 2021
 * 
 */

import React, { useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageContentHolder from '../components/PageContentHolder';
import './Routes.scss';
import CustomAlertContainer from '../components/CustomAlertContainer';
import { isServer } from '../helpers/server.helper';
import { history } from '../helpers';
import { setPage } from '../redux/actions';



const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const PrivateRoute = ({ mobileView, children, mainRef, ...rest }) => {
    const { app } = useSelector(state => state);
    const props = { children, ...rest };
    const prevLocationPathname = usePrevious(props?.location?.pathname);
    const isErrorRoute = app?.page?.key === 'nf' || app?.page?.key === "um" ? true : false;
    const dispatch = useDispatch();

    useEffect(() => {
        if (prevLocationPathname !== props?.location?.pathname) {
            !isServer && window?.scrollTo(0, 0);
        }
    }, [props?.location?.pathname, prevLocationPathname]);

    useEffect(() => {
        const currentPage = history?.location?.state?.page;
        if (currentPage) {
            dispatch(setPage(JSON.parse(currentPage)));
        }
    }, [history?.location?.pathname])

    return (
        <>
            <Route
                {...rest}
                render={
                    ({ location }) =>
                        <>
                            {/* <TopNavigation /> */}
                            <div className={"privateRoute"}>
                                {!mobileView && <Header topMenu={app?.page?.topMenu} isExternalRoute={isErrorRoute} />}
                                <main id={'main-content'} ref={mainRef} tabIndex={-1}>
                                    <PageContentHolder topMenu={app?.page?.topMenu}>
                                        {/* <BreadCrumbs/> */}
                                        {/* {app?.pageLoader ?<ProgressBar /> : children} */}
                                        {children}
                                    </PageContentHolder>
                                </main>
                                {!mobileView && <Footer topMenu={app?.page?.topMenu} />}
                            </div>
                            <CustomAlertContainer></CustomAlertContainer>
                        </>
                }
            />
            {/* <Redirect path="*" to='/404' /> */}
        </>
    );
}

export default PrivateRoute;
