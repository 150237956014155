import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { GetLocal, GetSession, history, Navigate, RemoveSession, SaveSession } from './helpers';
import { alertActions, getUserProfileInfo, mapIcimIdtoPlanyoId, setB2bUserDetails, setCountry, setLanguage, setMeetingPlace, setPage, setSite } from './redux/actions';
import PrivateRoute from './routes/PrivateRoute';
import ProtectedRoutes from './routes/ProtectedRoute'; //Authenticated routes
import PublicRoute from './routes/PublicRoute';
import './App.scss';
import routes from './routes/routes';
import { appDefaults, LOGINSCREEN, PLANYO, reactEnv, WRAPPER_URL } from './config';
import { setCountries, setMeetingPlaces, setPhonePrefixes } from './redux/actions/data.actions';
import Spinner from './components/Spinner';
import meetingPlaces from './data/meetingPlace.json';
import countries from './data/countries.json';
import phonePrefixes from './data/phonePrefixList.json';
import AccessDenied3 from './pages/AccessDenied3';
import { debouncer, formatCountries, formatMeetingPlace, formatPhonePrefix, getOpenAtTime, isServerUnderMaintenance, compareTime, reqToken, getCookie, logoutUser, captureICIMUserCode } from './services';
import { isValidURL } from './helpers';
import LogInPage from './pages/LogInPage';
import { withTranslation, useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { isServer } from './helpers/server.helper';
import SkipToContent from './components/SkipToContent';
import { useLocation } from 'react-router-dom';
import { fetchSitesData } from './services/json.service';
import { bookingTypes, navigationTypes, sessionTypes } from './redux/types';
import { userTypes } from './redux/types';
import { siteTypes } from './constants';

// define axios baseURL
axios.defaults.baseURL = WRAPPER_URL;

const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const app = useSelector(state => state?.app);
  const authentication = useSelector(state => state?.authentication);
  const booking = useSelector(state => state?.booking);
  const [mobileViewVar, setMobileViewVar] = useState(false);
  const mainRef = useRef();
  const location = useLocation();
  const queryString = new URLSearchParams(location?.search && !isServer ? location?.search : "");
  const icimActionError = queryString.get("error");
  const icimUserCode = queryString?.get("code") || null;


  useEffect(() => {
    !isServer && document?.getElementById('skip-to-content-container')?.focus();
  }, [history?.location])

  useEffect(() => {
    const listenerFunction = (event) => {
      const sessionBooking = JSON.parse(GetSession('booking') || '{}');
      if (sessionBooking?.paymentReload) {
        if (event.persisted) {
          // the page is restored from browser bfCache
          SaveSession('booking', JSON.stringify({ ...sessionBooking, paymentReload: false }));
          window.location.reload();
        } else {
          // the page is reloaded normally
          SaveSession('booking', JSON.stringify({ ...sessionBooking, paymentReload: false }));
        }
      }

    };
    window.addEventListener('pageshow', listenerFunction);
    const sessionBooking = JSON.parse(GetSession('booking') || '{}');
    if (sessionBooking?.processingPayment) {
      dispatch({ type: bookingTypes?.POPULATE_BOOKING, payload: sessionBooking?.booking })
    }

    if (sessionBooking?.processingPayment && app?.site?.type === siteTypes?.EVENT) {
      SaveSession("isBookingRestored", true);
    }

    // processing icim error and user code
    if (icimActionError === "access_denied") {
      dispatch({ type: sessionTypes?.ICIM_ACTION_ERROR, payload: icimActionError })
      SaveSession("icimActionError", icimActionError);
    }

    captureICIMUserCode({ code: icimUserCode, magicLink: false });
    return () => {
      window.removeEventListener('pageshow', listenerFunction);
    };
  }, [])

  useEffect(() => {
    let logoutTimer;
    const handleUserActivity = () => {
      clearTimeout(logoutTimer);

      if (authentication?.loggedIn) {
        logoutTimer = setTimeout(logoutUser, userTypes?.INACTIVE_DURATION, dispatch, app);
      }
    };

    if (authentication?.loggedIn) {
      logoutTimer = setTimeout(logoutUser, userTypes?.INACTIVE_DURATION, dispatch, app);
    }
    const eventListeners = ['mousemove', 'keydown', 'touchmove']?.map(eventType => {
      const listner = () => handleUserActivity();
      window.addEventListener(eventType, listner);
      return { eventType, listner };
    })

    return () => {
      clearTimeout(logoutTimer);
      eventListeners?.forEach(({ eventType, listener }) => {
        window.removeEventListener(eventType, listener);
      })
    };
  }, [authentication?.loggedIn])

  useEffect(() => {
    // clear alert on location change
    history.listen(() => {
      dispatch(alertActions.clear());
    });

    //test API call to check server activity
    isServerUnderMaintenance({ dispatch, history });
    // getConfig({ dispatch, history });
    (async () => {
      //access token api call for open routes
      if (!PLANYO && appDefaults?.environment !== 'dev') {
        let accessToken = getCookie("accessToken") || null;
        if (!accessToken) {
          await reqToken();
        }
      }

      //Make api calls for access token if cookie is empty
      ['mousemove', 'touchmove'].forEach(function (event) {
        !isServer && window?.addEventListener(event, debouncer(async function () {
          if (!PLANYO && appDefaults?.environment !== 'dev') {
            let accessToken = getCookie("accessToken") || null;
            if (!accessToken) {
              // console.log("Coords:", event.clientX, event.clientY);
              await reqToken();
            }
            else {
              let tokenExpiryTime = GetSession("TokenExpiresIn") || null;
              if (compareTime(tokenExpiryTime) <= 10) {
                await reqToken();
              }
            }
          }
        }, 250, true));
      });
    })()

    dispatch(setMeetingPlaces(formatMeetingPlace(meetingPlaces) || []));
    if (!app?.page?.route) {
      if (GetSession("currentPage")) {
        // Adding delay to let current page be set in Session Storage (from navigation hooks)
        setTimeout(() => {
          const page = JSON.parse(GetSession("currentPage") || 'null');
          const meetingPlace = GetSession('meetingPlace') && GetSession('meetingPlace') !== "undefined" ? JSON.parse(GetSession('meetingPlace') || '{}') : null;
          const meetingPlaceUrl = location?.pathname;
          const site = GetSession('site') && GetSession('site') !== "undefined" ? JSON.parse(GetSession('site') || '{}') : null;
          // set the page only when page is there in session and the selected meeting place is same as meeting place saved in session
          const siteType = ['event', 'space', 'service', 'room']?.find(item => meetingPlaceUrl?.includes(item));
          const isNavigateSpaceFromRoom = site?.type === 'room' && siteType === 'space' ? true : false;
          const siteCondition = siteType ? meetingPlaceUrl?.includes(site?.type) : true;
          const isB2bEvent = GetSession('portal') === 'b2b' ? true : false;
          if (isB2bEvent) {
            dispatch({ type: sessionTypes?.PORTAL, payload: 'b2b' })
          }
          if (page && meetingPlaceUrl?.includes(meetingPlace?.id) && siteCondition) {
            if (!isNavigateSpaceFromRoom) {
              dispatch(setPage(page));
            }
          }
        }, 300)
      }
    }
    dispatch(setCountries(formatCountries(countries) || []));
    dispatch(setPhonePrefixes(formatPhonePrefix(phonePrefixes) || []));

    if (!app?.selectedLanguage) {
      let lang = GetSession("selectedLanguage") || appDefaults.language;
      SaveSession("selectedLanguage", lang);
      dispatch(setLanguage(lang));
      const cont = GetSession('country') || JSON.stringify(appDefaults.country);
      i18n.changeLanguage(lang?.toLowerCase() + "-" + JSON.parse(cont)?.code);
    }

    if (!app?.country?.code) {
      const country = JSON.parse(GetSession("country") || JSON.stringify(appDefaults.country));
      dispatch(setCountry(country));
    }
    if (!app?.meetingPlace?.name) {
      if (GetSession("meetingPlace") && GetSession("meetingPlace") !== "undefined") {
        const meetingPlace = JSON.parse(GetSession("meetingPlace") || '{}');
        dispatch(setMeetingPlace(meetingPlace));
      }
    }
    if (!app?.site?.name) {
      if (GetSession("site")) {
        const site = JSON.parse(GetSession("site") || '{}');
        dispatch(setSite(site));
      }
    }
    if (!booking?.selectedResource?.name) {
      if (GetSession("selectedResource")) {
        const selectedResource = JSON.parse(GetSession("selectedResource") || '{}');
        dispatch({ type: "selectedResource", payload: selectedResource });
      }
    }
    if (!booking?.b2bUserDetails) {
      if (GetSession("b2bUserDetails")) {
        const b2bUserDetails = JSON.parse(GetSession("b2bUserDetails") || 'null');
        dispatch(setB2bUserDetails(b2bUserDetails));
      }
    }
    setTimeout(() => {
      dispatch({ type: "pageLoader", payload: false });
    }, 100);

    const queryString = new URLSearchParams(location.search && !isServer ? location.search : "");
    let view = queryString?.get('X-MobileApp-WebView')?.toLowerCase() === 'true' ? true : false;
    setMobileViewVar(view);

  }, [])

  /**
   * Description: UseEffect that fires the API call to fetch the ICIM user details upon the availability of ICIM configuration data and when user is not logged in
   * Dependency: {object} an object that contains ICIM configuration data
   */
  useEffect(() => {
    const userAuthentication = JSON.parse(GetLocal("authentication") || '{}');
    const userCode = JSON.parse(GetLocal("userCode") || '{}')?.code;
    if (!authentication?.loggedIn) {
      //isAuthUser: authentication variable for test env
      //userAuthentication: ICIM user authentication
      const isAuthUser = LOGINSCREEN ? JSON.parse(GetSession("isAuthUser") || '{}')?.auth : true;
      if ((userAuthentication?.loggedIn || userCode) && isAuthUser) {
        getUserProfileInfo({ dispatch, userAction: "signIn" });
      }
    } else if (authentication?.loggedIn && !userCode && !userAuthentication?.loggedIn) {
      dispatch({ type: userTypes.LOGOUT });
      setTimeout(() => {
        Navigate(navigationTypes?.GENERALBOOKINGPAGE, { dispatch, history });
      }, 100)
    }
  }, [GetLocal('userCode')])


  /**
   * UseEffect executes when an API response code is 401, i.e., unauthorized
   * It checks for the reload delay in session and reloads the page after applying that specific delay
   * Before reloading, it doubles the previous delay value and stores it in Session
   */
  useEffect(() => {
    if (!PLANYO) {
      if (!GetSession('reloadDelay')) {
        SaveSession('reloadDelay', JSON.stringify({ delay: 500, count: 0 }));
      }
      else {
        const reloadDelay = JSON?.parse(GetSession('reloadDelay') || 'null');
        setTimeout(() => {
          if (GetSession('error') === '401' && !isServer && reloadDelay?.count < 16) {
            RemoveSession('error');
            SaveSession('reloadDelay', JSON.stringify({ delay: reloadDelay?.delay * 2, count: reloadDelay?.count + 1 }));
            window.location.reload();
          }
        }, parseInt(reloadDelay?.delay))
      }
    }
  }, [GetSession('error')])

  useEffect(() => {
    if (JSON.parse(GetSession("siteInfoDisplay")) && (Object?.keys(app?.meetingPlace)?.length > 0)) {
      let openAtValue = getOpenAtTime();
      dispatch(setMeetingPlace({ ...app?.meetingPlace, ...JSON.parse(GetSession("siteInfoDisplay") || '{}'), openAt: openAtValue }));
    }
  }, [GetSession("siteInfoDisplay")])

  useEffect(() => {
    if (JSON.parse(GetSession("siteInfoDisplay")) && (Object?.keys(app?.meetingPlace)?.length > 0) && !app?.meetingPlace?.openingHours) {
      let openAtValue = getOpenAtTime();
      dispatch(setMeetingPlace({ ...app?.meetingPlace, ...JSON.parse(GetSession("siteInfoDisplay") || '{}'), openAt: openAtValue }));
    }
  }, [app?.meetingPlace?.openingHours])


  useEffect(() => {
    let validURL = isValidURL({ dispatch, i18n, url: history?.location?.pathname })
    if (app?.error || !validURL) {
      Navigate(20000, { dispatch, history });
    }
  }, [history, app?.error])

  useEffect(() => {
    if (app?.meetingPlace?.id && Object.keys(app?.meetingPlace).length > 0 && Object.keys(app?.site).length === 0) {
      const sSite = JSON.parse(GetSession('site'), "{}");
      if(!sSite?.type) { // changed by naveen
        fetchSitesData((res) => {
          const site = res?.find(s => (s?.meetingPlaceId === app?.meetingPlace?.mpid) && s?.type?.toLowerCase() !== 'room');
          if (site) dispatch(setSite(site));
        })
      }
    }
  }, [app?.meetingPlace])

  useEffect(() => {
    if ((authentication?.userDetails?.userId && authentication?.userDetails?.email) && !authentication?.userDetails?.planyoUserId) {
      dispatch(mapIcimIdtoPlanyoId(authentication?.userDetails));
    }
  }, [authentication?.userDetails?.userId])

  useEffect(() => {
    if (LOGINSCREEN) {
      if (!JSON.parse(GetSession("isAuthUser") || '{}')?.auth && !location?.pathname?.includes('admin-access-denied')) {
        const redirectTo = location.pathname + location.hash;
        SaveSession("redirectTo", redirectTo);
        Navigate(navigationTypes.LOGIN, { dispatch, history })
      }
    }
  }, [JSON.parse(GetSession("isAuthUser") || '{}')?.auth])

  const filteredRoutes = routes?.filter(r => r.isPublicRoute) || [];

  return (
    <HelmetProvider>
      <div className="relative">
        <Helmet htmlAttributes={{ lang: i18n.language }}>

          {/* <!-- OneTrust Cookies Consent Notice start for qa.booking.livat.com --> */}
          {/* {!client && */}
          {reactEnv === "prod" &&
            <>
              <title>{`Booking Tool ${app?.meetingPlace?.name ? ' | ' + app?.meetingPlace?.name : ''}`}</title>
              <meta name="description" content="Booking" />
              <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true"
                type="text/javascript" charSet="UTF-8" data-domain-script={appDefaults?.oneTrustDomainKey} defer></script>
              {/* <script type="text/javascript">
                function OptanonWrapper() { }
              </script> */}

              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: 'function OptanonWrapper() {}'
                }} />
            </>
          }
          {/* <!-- OneTrust Cookies Consent Notice end for qa.booking.livat.com --></div> */}
        </Helmet>
        {/* <pre>{JSON.stringify(appDefaults, null, 2)}</pre> */}
        {/* App spinner for refresh the page from web browser */}
        {(app?.appLoader) &&
          <Spinner style={{ backgroundColor: "rgba(255, 255, 255, 1)" }} />
        }

        {/* We are using connectRouter here because of redux, in react-router component 
        reload problem is there after changing the route */}
        {(app?.pageLoader || app?.apiLoader || app?.funLoader) &&
          <Spinner />
        }
        <Switch>
          {/* <Route exact path="/404" component={NotFoundPage} /> */}
          <Route exact path='/admin-access-denied' component={AccessDenied3} />
          {LOGINSCREEN && <Route exact path='/login' component={LogInPage} />}

          {(LOGINSCREEN ? JSON.parse(GetSession("isAuthUser") || "null")?.auth : true) && filteredRoutes?.map(({ component: Component, path, exact, redirectTo }) => (
            <PublicRoute path={`/${path}`} key={path} exact={exact} >
              {redirectTo ? <Component to={`/${redirectTo}`} /> : <Component />}
            </PublicRoute>
          ))}
          {(LOGINSCREEN ? JSON.parse(GetSession("isAuthUser") || "null")?.auth : true) && (
            <>
              <SkipToContent mainRef={mainRef} />
              <PrivateRoute mobileView={mobileViewVar} path="/" mainRef={mainRef}>
                <ProtectedRoutes />
              </PrivateRoute>
            </>
          )}
        </Switch>
        {/* <CustomCookieConsent /> */}
      </div>
    </HelmetProvider>
  );
};

export default withTranslation()(App);