import { GetLocal, GetSession, Navigate, SaveLocal, SaveSession } from "../helpers";
import { setError } from "../redux/actions";
import { API_URL_SERVER_MAINTENANCE, PLANYO } from '../config';
import moment from "moment";
import { API } from "./api.service";
import { Cookies } from 'react-cookie';
import { resourceTypes, sessionTypes } from "../redux/types";
import { fetchPhonePrefixList } from "./json.service";
import { siteTypes } from "../constants";
import { eventTypes } from "../constants/eventTypes.constants";
import { CONFIRMED_STATUSES, PENDING_STATUSES } from "../constants/reservationStatus.constants";

const cookies = new Cookies();

/**
 * @function deepCopy
 * @description function for creating a new object out of the existing object passed as input
 * @param {object} data object that needs to be cloned 
 * @returns {object} the newly created object
 */
export const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
}

/**
 * @function formatPhoneNumber
 * @description function that accepts a phone number as input and returns the phone number in the international phone number format
 * @example 
 *         formatPhoneNumber('883425XXXX') => 883 425 XXXX
 * @param {string} phoneNumber string that denotes the user's phone number that needs formatting 
 * @returns {string} the user's phone number converted to the international standard format
 */
export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.includes(' ')) return phoneNumber;
  if (phoneNumber.length < 4) return phoneNumber;
  else if (phoneNumber.length < 7) {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(0, 3)}`;
  }
  else {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 10)}`;
  }

}

/**
 * @function delay
 * @description an asynchronous function that adds a delay of time corresponding to the time delay given as input
 * @param {number} [time = 0] the time delay that is required
 * @returns {function} returns a function that in turn returns a Promise which is resolved after the delay time has passed
 */
export const delay = (time = 0) => (result) => new Promise((resolve) => setTimeout(() => resolve(result), time));

/**
 * @function objExists
 * @description a function to check whether an object with the given key value pair exists in the given array or not
 * @param {object []} arr an array of objects  
 * @param {string} key key of an object  
 * @param {string} value value that we need to check against the given key
 * @returns {boolean} returns true if the object containing the given key-value pair exists in the given array, otherwise returns false
 */
export const objExists = (arr = [], key, value) => {
  let o = arr?.filter(item => item[key] === value) || [];
  if (o.length > 0) {
    return true;
  } else {
    return false;
  }
}

/**
 * @function formatCountries
 * @description a function that picks every country object in the array and standardizes (removing leading and trailing spaces and converting text to uppercase) its values
 * @param {object []} countries an array of objects containing country details
 * @returns {object []} returns the array that contains the country objects in which the details have been formatted
 */
export const formatCountries = (countries) => {
  let output = countries?.map(currentCountry => {
    return {
      name: currentCountry["name"]?.trim(),
      code: currentCountry["code"]?.toUpperCase()?.trim(),
      translationKey: currentCountry["translationKey"]?.toUpperCase()?.trim(),
      languages: currentCountry["languages"]?.map(item => item.toLowerCase())
    }
  });
  return output;
}

/**
 * @function formatMeetingPlace
 * @description a function that picks every meeting place object in the array and standardizes (removing leading and trailing spaces and converting text to uppercase/lowercase) its values
 * @param {object []} meetingPlace an array of objects containing meeting place details
 * @returns {object []} returns the array that contains the meeting place objects in which the details have been formatted
 */
export const formatMeetingPlace = (meetingPlace) => {
  let output = meetingPlace?.map(currentMeetingPlace => {
    return {
      ...currentMeetingPlace,
      id: currentMeetingPlace["id"]?.toLowerCase()?.trim(),
      countryCode: currentMeetingPlace["countryCode"]?.toUpperCase()?.trim(),
      mpid: currentMeetingPlace["mpid"]?.trim(),
      openingHours: null,
      openAt: null
    }
  });
  return output;
}

/**
 * @function formatSites
 * @description a function that picks every site object in the array and standardizes (removing leading and trailing spaces and converting text to lowercase) its values
 * @param {object []} sites an array of objects containing site details
 * @returns {object []} returns the array that contains the site objects in which the details have been formatted
 */
export const formatSites = (sites) => {
  let output = sites?.map(currentSite => {
    return {
      ...currentSite,
      meetingPlaceId: currentSite["meetingPlaceId"]?.trim(),
      type: currentSite["type"]?.toLowerCase()?.trim()
    }
  })
  return output;
}

/**
 * @function formatPhonePrefix
 * @description a function that picks every phonePrefix object in the array and standardizes (removing leading and trailing spaces) its values
 * @param {object []} prefix an array of objects containing phone prefix details
 * @returns {object []} returns the array that contains the phone prefix objects in which the details have been formatted
 */
export const formatPhonePrefix = (prefix) => {
  let output = prefix?.map(currentPrefix => {
    return {
      ...currentPrefix,
      country: currentPrefix["country"]?.trim(),
      prefix: currentPrefix["prefix"]?.trim()
    }
  })
  return output;
}

/**
 * @function resourceErrorFunction
 * @description a function that updates the application's error state (Session OR Redux) if a resource is unpublished or if an API returns an error
 * @param {object} errorObj an object containing the error messages as returned by the API in case of an error} 
 * @param {function} dispatch a function to send actions into the redux store
 * @param {boolean} isPublished variable that contains true if the resource is published, else contains false
 * @returns {void}
 */
export const resourceErrorFunction = (errorObj = {}, dispatch, error) => {
  if (!error || Object.keys(errorObj).length > 0) {
    let message = '';
    if (Object.keys(errorObj).length > 0) {
      // message = errorObj?.response_message || errorObj?.message || errorObj?.title;
      message = errorObj?.message;
      if (!PLANYO && errorObj?.message === 'Unauthorized. Access token is missing or invalid.') {
        message = '';
      }
    } else if (GetSession("error") !== null) {
      message = GetSession("error");
    } else if (!error) {
      message = "Resource is not published";
    } else {
      message = "Invalid Resource ID";
    }
    dispatch(setError(message));
  }
}

/**
 * @function debouncer
 * @description a function that takes in another function (debounced function) that might get called repeatedly, and does the following tasks to limit the number of times the function gets called:
 *              - starts with 0 timeout 
 *              - if the debounced function is called again, reset the timer to the specified delay 
 *              - in case of timeout, call the debounced function. Thus every call to a debounce function, resets the timer and delays the call.  
 * @param {function} func the debounced function that may be called repetitively 
 * @param {number} wait the delay timer 
 * @param {boolean} immediate a boolean flag which when true, helps execute the function immediately without further delay, given that there's no ongoing timeout 
 * @returns {function} returns an anonymous function that performs the task of reseting the timer(during a new function call) and calling the function when the timer runs out
 */
export const debouncer = (func, wait, immediate) => {
  let timeout;
  return function () {
    let context = this, args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/**
 * @function getOpenAtTime
 * @description a function that picks a string from Session object siteInfoDisplay, which contains the opening and closing times of a meeting place
 *              - the function breaks up the string and finds the opening and closing hours of the meeting place for each day of the week
 *              - it then returns the opening and closing hours of the meeting place for the current day
 * @returns {string} a string that contains the opening and closing hours of the meeting place for the current day
 */
export const getOpenAtTime = () => {
  const list = [];
  const split = JSON.parse(GetSession("siteInfoDisplay"))?.openingHours?.split('\r\n');
  for (let index = 0; index < split?.length; index += 4) {
    const sublist = [];
    let j = index;
    while (j < index + 4) {
      sublist?.push(split[j])
      j++;
    }
    list?.push(sublist);
  }
  let daysArray = ["S_MONDAY", "S_TUESDAY", "S_WEDNESDAY", "S_THURSDAY", "S_FRIDAY", "S_SATURDAY", "S_SUNDAY"];
  let openingHoursObject = {};
  list.forEach(item => {
    let start = daysArray?.indexOf(item[0]);
    let end = daysArray?.indexOf(item[2]);
    if (!item[2]) {
      openingHoursObject[item[0]] = [item[1]] + " - " + [item[3]];
    }
    else {
      while (start <= end) {
        openingHoursObject[daysArray[start]] = [item[1]] + " - " + [item[3]];
        start = start + 1;
      }
    }
  });
  // SaveSession("opensAtList", JSON.stringify(openingHoursObject));
  let dayOfWeek = moment().day();
  let openAtValue = openingHoursObject[daysArray[dayOfWeek - 1]] || null;
  // dispatch(setMeetingPlace({...app?.meetingPlace, openAt: openAtValue}));
  return openAtValue;
}

/**
 * @function jsonToQueryString
 * @description a function that takes a JSON object as input and converts it into a query string of key-value pairs
 * @param {object} json a JSON object that contains key-value pairs to be passed as a query string
 * @returns {string} returns the query string by joining the key-value pairs of the JSON object
 */
export const jsonToQueryString = (json) => {
  let delim = "";
  let str = "?";
  for (const key in json) {
    str = str + delim + key + "=" + encodeURIComponent(json[key]);
    delim = "&";
  }
  return str;
}

/**
 * @function assignUnit
 * @description this function takes user-assigned unit numbers as input and:
 *              - for WRAPPER: it returns an object containing a single key that contains an array of unit(s) to be assigned to user
 *              - for PLANYO: it returns an object containing a list of key-value pairs for every unit to be assigned to user
 * @example
 *        - for WRAPPER: assignUnit([1,2,3]) => {assignment1: "1, 2, 3"}
 *        - for PLANYO: assignUnit([1,2,3]) => {assignment1: 1, assignment2: 2, assignment3: 3}
 * @param {number []} units a list of units to be assigned to a user when booking a resource (that requires unit assignment) 
 * @returns {object} returns an object that contains the units to be assigned to the user, in separate formats for PLANYO and WRAPPER
 */
export const assignUnit = (units) => {
  let result = {};
  if (!PLANYO) {
    result['assignment1'] = units;
  }
  else {
    let i = 1;
    let unitsArr = units?.split(",");
    for (let unit of unitsArr) {
      result[`assignment${i}`] = unit;
      i++;
    }
  }
  return result;
}

/**
 * @function processPeriodicEvents
 * @description function for segregating the date from the total occurance part 
 * @param {string} periodType 'daily' | 'weekly' | 'monthly'  
 * @param {string} currentDate date string 
 * @returns {object} object containing the trimmed date and prefix string
 */
const processPeriodicEvents = (periodType, currentDate) => {
  let dateStringArr = currentDate?.split(periodType);
  currentDate = dateStringArr[1]?.trim();
  let totalOccurance = dateStringArr[0]?.split("*")[0]?.trim();
  return [
    currentDate,
    `${totalOccurance} * ${periodType}`
  ]
}

/**
 * @function processEventDates
 * @description accepts an array of time slots for the selected event and returns an array of timeslots with date and time in their standard formats
 * @param {array} durationArray array of strings containing the time slots for the selected event 
 * @returns {array} returns an array of strings containing the date/times processed to their standard formats
 */
export const processEventDates = (durationArray = []) => {
  let processedDuration = [];
  durationArray?.forEach(currentDate => {
    currentDate = currentDate?.toLowerCase()?.trim();
    if (currentDate) {
      let prefixString = "";
      //processing for recurring event
      if (currentDate?.includes("daily")) {
        [currentDate, prefixString] = processPeriodicEvents("daily", currentDate);
      } else if (currentDate?.includes("weekly")) {
        [currentDate, prefixString] = processPeriodicEvents("weekly", currentDate);
      } else if (currentDate?.includes("monthly")) {
        [currentDate, prefixString] = processPeriodicEvents("monthly", currentDate);
      }

      let sanitizedDate = "";
      let dateTimeArr = [];
      if (currentDate?.includes(".")) {
        dateTimeArr = currentDate?.replaceAll(".", "-")?.split(" ");
      } else if (currentDate?.includes("/")) {
        dateTimeArr = currentDate?.replaceAll("/", "-")?.split(" ");
      } else if (currentDate?.includes("-")) {
        dateTimeArr = currentDate?.split(" ");
      }
      // the below else if handles the following cases: Apr 19, 2023 | April 19, 2023 | 19 April 2023 | 19 Apr 2023 
      else if (currentDate?.split(" ")?.length > 2 && !prefixString) {
        let tempCurrentDate = currentDate?.split(" ")
        if (tempCurrentDate?.length === 3) {
          currentDate = `${tempCurrentDate[0]} ${tempCurrentDate[1]} ${tempCurrentDate[2]}`
        } else if (tempCurrentDate?.length === 5) {
          currentDate = `${tempCurrentDate[0]} ${tempCurrentDate[1]} ${tempCurrentDate[2]}-${tempCurrentDate[3]}${tempCurrentDate[4]}`
        } else {
          currentDate = `${tempCurrentDate[0]} ${tempCurrentDate[1]} ${tempCurrentDate[2]}-${tempCurrentDate[3]}`;
        }
        dateTimeArr = currentDate?.split("-");
        const parsedDate = moment(dateTimeArr[0], ['MMMM D YYYY', 'MMM D YYYY', 'D MMM YYYY', 'D MMMM YYYY']);
        if (parsedDate?.isValid()) {
          dateTimeArr[0] = parsedDate?.format("YYYY-MM-DD");
        }
      }
      // the below else handles the default case when we recieve the dateTime in the following format:
      // 2023-07-31 10:30(optional) 
      else {
        dateTimeArr = currentDate?.split(" ");
      }


      sanitizedDate = moment(dateTimeArr[0], ["YYYY-MM-DD", "MM-DD-YYYY"])?.format("YYYY-MM-DD");
      if (sanitizedDate?.toLowerCase() === "invalid date") {
        sanitizedDate = moment(dateTimeArr[0], "DD-MM-YYYY")?.format("YYYY-MM-DD");
      }

      //Processing Time
      let sanitizedTime = dateTimeArr[1] || "00:00";
      if (dateTimeArr?.length === 3) {
        sanitizedTime += dateTimeArr[2];
      }
      if (sanitizedTime?.includes("am") || sanitizedTime?.includes("pm")) {
        sanitizedTime = moment(sanitizedTime, "h:mm a");
        sanitizedTime = moment(sanitizedTime)?.format("HH:mm");
      }

      prefixString ? processedDuration?.push(`${prefixString} ${sanitizedDate} ${sanitizedTime}`) : processedDuration?.push(`${sanitizedDate} ${sanitizedTime}`);
    }
  });
  return processedDuration;
}

/** 
 * @function isServerUnderMaintenance
 * @description fires an API call that returns whether or not the server is under maintenance
 * @param {object} param0 a destructured object containing dispatch and history functions 
 * @returns {void}
 */
export const isServerUnderMaintenance = async ({ dispatch, history }) => {
  await API.get({
    path: {
      path: API_URL_SERVER_MAINTENANCE,
      baseURL: ""
    }, key: "SERVER-Maintainence"
  }).then(response => {
    if (response?.data?.serverUnderMaintenance) {
      setTimeout(() => {
        Navigate(30000, { dispatch, history })
      }, 200)
    }
  });
}

/**
 * @function getCookie
 * @description retrieves the value of the cookie whose name is denoted by the parameter 'cname' from the cookies storage
 * @param {string} cname string that denotes the cookie name 
 * @returns {string} returns a string that is the value of the cookie whose name is denoted by the parameter 'cname'
 */
export const getCookie = (cname) => {
  let decodedCookie = cookies.get(cname) || ""; //decodeURIComponent(document?.cookie);
  return decodedCookie;
}

/**
 * @function setCookie
 * @description stores a cookie as a key value pair in the Application's cookie storage, also specifying its expiry time
 * @param {string} cname string that denotes cookie name
 * @param {string} cvalue string that denotes the cookie value 
 * @param {string} expiryTime string that denotes the time in seconds after which cookie should expire 
 */
export const setCookie = (cname, cvalue, expiryTime) => {
  const d = new Date();
  d.setTime(d.getTime() + (expiryTime * 1000));
  let expires = d;
  if (cname === 'acessToken') {
    SaveSession("TokenExpiresIn", moment(d.toUTCString()));
    SaveSession("reloadDelay", JSON.stringify({ delay: 200, count: 0 }));
  }

  cookies.set(cname, cvalue, { path: '/', expires });
}

/**
 * @function getConfig
 * @description function for fetching ICIM configuration that will be used in API's related to ICIM. The configuration will be store in redux
 * @param {*} param0 object that contains:
 *                      - dispatch method for updating the redux store
 *                      - history for updating the history object
 * @returns {void}
 */
export const getConfig = async ({ dispatch, history }) => {
  await API.get({
    path: {
      path: "../appConfig",
      baseURL: ""
    },
    key: 'getConfig'
  }).then(response => {
    dispatch({ type: sessionTypes?.CONFIGURATION, payload: { name: "icim", value: response?.data?.icim } });
    SaveSession("config", JSON.stringify({ icim: response?.data?.icim }));
  });
}

/**
 * @function handleInternationalPhoneNumber
 * @description takes in a complete phone number and segregates its prefix(country code) and number parts:
 *              - it compares the number with the phone prefix list and progressively generates all possible combinations
 *              - it picks the last prefix generated and thus, replaces it with blank string in the complete phone number to get just the number
 * @example handleInternationalPhoneNumber(+4698XXXXXXXX) => {+46, 98XXXXXXXX}
 * @param {string} phoneNumber string that denotes the complete phone number 
 * @returns {object} an object that contains the phone prefix/ country code and the phone number
 */
export const handleInternationalPhoneNumber = (phoneNumber) => {
  const possibleDDIs = [];

  fetchPhonePrefixList((res) => {
    res.forEach((country) => {
      if (phoneNumber.startsWith(country.prefix.replace(" ", ""))) {
        possibleDDIs.push(country);
      }
    });
  });
  const phonePrefix = possibleDDIs[possibleDDIs?.length - 1]?.prefix;
  const number = phoneNumber?.replace(phonePrefix, "");
  return {
    phonePrefix,
    number
  };
}

/**
 * @function disableModal
 * @description a function that updates the displayModal key in redirectRoute stored in Session, so that a specific modal is not displayed in case of page reload
 * @return {void}
 */
export const disableModal = () => {
  let redirectRoute = JSON.parse(GetLocal("redirectRoute")) || null;
  if (redirectRoute?.displayModal) {
    redirectRoute = { ...redirectRoute, displayModal: false };
    SaveLocal("redirectRoute", JSON.stringify(redirectRoute));
  }
}

export const compareArrays = (a, b) =>
  a.length === b.length &&
  a.every((element, index) => element === b[index]);

export const dateArrSorting = (a, b, order, dateGenerator) => {
  let multiplier;
  if (order === 'asc') {
    multiplier = 1;
  } else {
    multiplier = -1;
  }
  const first = dateGenerator(a);
  const second = dateGenerator(b);
  if (first?.isBefore(second)) {
    return (-1 * multiplier);
  } else if (first?.isAfter(second)) {
    return (1 * multiplier);
  }
  return 0;
}

export const numArrSorting = (a, b, order) => {
  const first = parseFloat(a);
  const second = parseFloat(b)
  let multiplier;
  if (isNaN(first) || isNaN(second)) {
    multiplier = 0;
  } else {
    if (order === 'asc') {
      multiplier = 1;
    } else {
      multiplier = -1;
    }
  }
  return (multiplier * (first - second));
}

export const stringArrSorting = (a, b, order, lang = 'en', options = {}) => {
  let multiplier;
  if (order === 'asc') {
    multiplier = 1;
  } else {
    multiplier = -1;
  }
  return (multiplier * (a?.localeCompare(
    b,
    lang,
    {
      ignorePunctuation: true,
      numeric: true,
      ...options
    }
  )))
}

export const resourceListSorting = (app, resource, dateGenFn, dispatch, setSortedResourcesMemo, site) => {
  const sortByKeys = Object.keys(app?.meetingPlace?.sortBy || {});
  const sortBy = app?.meetingPlace?.sortBy;
  if (sortByKeys?.length > 0 && sortByKeys?.includes(site)) {
    if (sortBy?.[site]?.column && sortBy?.[site]?.order && sortBy?.[site]?.datatype) {
      if (Object.keys(resource?.list[0] || {})?.includes(sortBy?.[site]?.column)) {
        let sortedResources;
        switch (sortBy?.[site]?.datatype) {
          case 'date':
            sortedResources = resource?.list?.slice()?.sort((a, b) => dateArrSorting(
              a?.[sortBy?.[site]?.column],
              b?.[sortBy?.[site]?.column],
              sortBy?.[site]?.order,
              dateGenFn
            ))
            break;
          case 'number':
            sortedResources = resource?.list?.slice()?.sort((a, b) => numArrSorting(
              a?.[sortBy?.[site]?.column],
              b?.[sortBy?.[site]?.column],
              sortBy?.[site]?.order
            ))
            break;
          case 'string':
          default:
            sortedResources = resource?.list?.slice()?.sort((a, b) => stringArrSorting(
              a?.[sortBy?.[site]?.column],
              b?.[sortBy?.[site]?.column],
              sortBy?.[site]?.order,
              app?.selectedLanguage?.toLowerCase()
            ));
            break;
        }
        setSortedResourcesMemo(sortedResources?.map(it => it?.id));
        dispatch({ type: resourceTypes?.RESOURCES, payload: sortedResources });
        switch (site) {
          case siteTypes.EVENT:
            dispatch({ type: resourceTypes?.EVENTS_LIST, payload: sortedResources });
            break;
          case siteTypes.MEETINGROOM:
            dispatch({ type: resourceTypes?.ROOMS_LIST, payload: sortedResources });
            break;
          case siteTypes.SERVICE:
            dispatch({ type: resourceTypes?.SERVICES_LIST, payload: sortedResources });
            break;
          case siteTypes.SPACE:
            dispatch({ type: resourceTypes?.SPACES_LIST, payload: sortedResources });
            break;
          default:
            break;
        }
      }
    }
  }
}

export const processPriceType = (resource = {}, translation, type) => {
  let val = '';
  let priceType = parseInt(type || resource?.price_type)
  switch (priceType) {
    case 0:
      if (isNaN(parseFloat(resource?.min_rental_time))) {
        val = translation === null ? '15 min' : `15 ${translation('S_MINUTES')}`;
      } else {
        if (parseFloat(resource?.min_rental_time) === 0.5) {
          val = translation === null ? '30 min' : `30 ${translation('S_MINUTES')}`;
        } else {
          if (parseFloat(resource?.min_rental_time) === 1) {
            val = translation === null ? 'hour' : translation('S_HOUR');
          } else {
            if (parseFloat(resource?.min_rental_time) > 167 || (resource?.properties?.rental_starting_days && resource?.min_rental_time === '24')) {
              val = translation === null ? 'week' : translation('S_WEEK');
            } else {
              val = translation === null ? '15 min' : `15 ${translation('S_MINUTES')}`;
            }
          }
        }
      }
      break;
    case 3:
      val = translation === null ? 'unit' : translation('S_UNIT');
      break;
    case 6:
      val = translation === null ? 'hour' : translation('S_HOUR');
      break;
    case 7:
      val = translation === null ? 'day' : translation('S_DAY');
      break;
    case 8:
      val = translation === null ? 'week' : translation('S_WEEK');
      break;
    case 14:
    default:
      break;
  }
  return val;
}

export const calcQuantForPayment = (resource, reservationSlot, priceRule = {}, translation = () => { }) => {
  let total = 0;
  let unit = '';
  const priceType = processPriceType(resource, null, priceRule?.unit_type);
  const startDate = moment(reservationSlot?.date);
  const endDate = moment(reservationSlot?.checkOutDate || reservationSlot?.date);
  if (reservationSlot?.timeIn && reservationSlot?.timeOut) {
    const timeIn = reservationSlot?.timeIn?.split(':');
    const timeOut = reservationSlot?.timeOut?.split(':');
    startDate?.set({ 'hours': timeIn[0], 'seconds': timeIn[1] });
    endDate?.set({ 'hours': timeOut[0], 'seconds': timeOut[1] });
  }
  switch (priceType) {
    case '15 min':
      total = (endDate?.diff(startDate, 'minutes', true) + (resource?.isWeeklyResource ? 1440 : 0)) / 15;
      unit = `15 ${translation("S_MINUTE")}`;
      break;
    case '30 min':
      total = (endDate?.diff(startDate, 'minutes', true) + (resource?.isWeeklyResource ? 1440 : 0)) / 30;
      unit = `30 ${translation("S_MINUTE")}`;
      break;
    case 'hour':
      total = (endDate?.diff(startDate, 'minutes', true) + (resource?.isWeeklyResource ? 1440 : 0)) / 60;
      unit = total > 1 ? translation("S_HOURS")?.toLowerCase() : translation("S_HOUR")?.toLowerCase();
      break;
    case 'day':
      total = (endDate?.diff(startDate, 'days', true) + (resource?.isWeeklyResource ? 1 : 0));
      unit = total > 1 ? translation("S_DAYS")?.toLowerCase() : translation("S_DAY")?.toLowerCase();
      break;
    case 'week':
      total = (endDate?.diff(startDate, 'days', true) + (resource?.isWeeklyResource ? 1 : 0)) / 7;
      unit = total > 1 ? translation("S_WEEKS")?.toLowerCase() : translation("S_WEEK")?.toLowerCase();
      break;
    case 'unit':
      total = reservationSlot?.quantity;
      unit = translation("S_UNIT")?.toLowerCase();
      break;
    default:
      break;
  }
  return { total, unit };
}

/**
 * @function findImageArray
 * @description a function that extracts all the paths from the imageObj object and compiles them into a single array
 * @param {object} imageObj an object that contains id, path and title properties corresponding to a particular resource
 * @returns {array} an array containing all the image paths
 */
export const findImageArray = (imageObj) => {
  return Object?.keys(imageObj)?.map(item => imageObj[item]?.path);
}

/**
 * @function evalReservationStatus
 * @description a function that takes status of the reservation as input to tell if booking is confirmed or pending
 * @param {string} status status code for the reservation
 * @returns {string} string in the form of 'pending' or 'confirmed'
 */
export const evalReservationStatus = status => {
  let result = '';
  const statusCode = parseInt(status);
  if (PENDING_STATUSES.includes(statusCode)) {
    result = 'pending';
  } else if (CONFIRMED_STATUSES.includes(statusCode)) {
    result = 'confirm';
  }
  return result;
}

/**
 * @function parseAmount
 * @description a function that checks if the resource's price has non-zero digits after the decimal point; if so, then it returns the price as it is, else it converts it into an integer
 * @param {float} amount a floating point number that represents the booking price
 * @returns string that represents the booking price
 */
export const parseAmount = (amount) => {
  const amountValue = amount.toString();
  const [integerAmount, decimalAmount] = amountValue?.split('.');
  if (decimalAmount === '00') {
    return integerAmount;
  } else {
    return amountValue;
  }
}

/**
 * @function getEventType
 * @description function to get which type of event it is
 * @param {Array.<string>} eventDates a list of pre-formatted event dates in string format
 * @param {boolean} weeklyCondition condition to check if event type is weekly
 * @returns {string} that represents the event type
 */
export const getEventType = (eventDates, weeklyCondition) => {
  let type = eventTypes.SINGLE;
  if (weeklyCondition) {
    type = eventTypes.WEEKLY;
  } else {
    if (eventDates?.length === 1) {
      const periodicRegex = RegExp('weekly|daily|monthly', 'gm');
      let match = periodicRegex.exec(eventDates[0])
      if (match !== null) {
        type = match[0] === 'daily' ? eventTypes.PERIODIC_DAILY : (match[0] === 'weekly' ? eventTypes.PERIODIC_WEEKLY : eventTypes?.PERIODIC_MONTHLY);
      }
    } else if (eventDates?.length > 1) {
      const firstDate = eventDates[0]?.split(' ');
      const sameDay = eventDates?.reduce((acc, curr) => acc && (curr?.split(' ')[0] === firstDate[0]), true);
      const sameTime = eventDates?.reduce((acc, curr) => acc && (curr?.split(' ')[1] === firstDate[1]), true);
      if (sameDay && !sameTime) {
        type = eventTypes.DIFF_TIME;
      }
      if (!sameDay) {
        if (sameTime) {
          type = eventTypes.DIFF_DAY;
        } else {
          type = eventTypes.DIFF_DATE;
        }
      }
    }
  }
  return type;
}

/**
 * @function displayEventDate
 * @description function to display the event date in the proper formatted format according to the event type
 * @param {Set.<string>} distinctDays contains distinct start and end dates in the form of JSON stringified objects
 * @param {string} eventType type of event
 * @returns {string} formatted event date to display
 */
export const displayEventDate = (distinctDays, eventType) => {
  let res = '';
  const distincDaysArr = [...distinctDays];
  const isWeekly = eventType === eventTypes.WEEKLY;
  if (distincDaysArr.length === 1 && !isWeekly) {
    res = moment(JSON.parse(distincDaysArr[0])?.startDate).format('ddd, DD MMMM YYYY');
  } else {
    const years = new Set();
    const months = new Set();
    let periodicStrSuffix = '';
    const isPeriodic = [eventTypes.PERIODIC_DAILY, eventTypes.PERIODIC_WEEKLY, eventTypes.PERIODIC_MONTHLY].includes(eventType);
    res = distincDaysArr.map((it, ind) => {
      const parsed = JSON.parse(it);
      parsed.startDate = moment(parsed?.startDate);
      parsed.endDate = moment(parsed?.endDate);
      if (isPeriodic && (ind === distincDaysArr.length - 1)) {
        periodicStrSuffix =
          eventType === eventTypes.PERIODIC_DAILY
            ? 'every day'
            : (
              eventType === eventTypes.PERIODIC_WEEKLY
                ? `on ${parsed.startDate.format('dddd')}s`
                : `on every ${parsed.startDate.format('Do')} of the month`
            );
      }
      years.add(parsed.startDate.format('YYYY'));
      months.add(parsed.startDate.format('MMMM'));
      if (isWeekly) {
        years.add(parsed.endDate.format('YYYY'));
        months.add(parsed.endDate.format('MMMM'));
        return `${parsed.startDate.format('DD MMMM YYYY')} - ${parsed.endDate.format('DD MMMM YYYY')}`
      } else {
        return parsed.startDate.format(isPeriodic ? 'DD MMMM YYYY' : 'ddd, DD MMMM YYYY');
      }
    }).join(isPeriodic ? ' - ' : ' / ');
    const yearsArr = [...years];
    const monthsArr = [...months];
    if (yearsArr.length === 1) {
      const yearRegex = new RegExp(`\\s${yearsArr[0]}`, 'g');
      res = res.replaceAll(yearRegex, '');
      if (monthsArr.length === 1) {
        const monthRegex = new RegExp(`\\s${monthsArr[0]}`, 'g');
        res = res.replaceAll(monthRegex, '');
        res = `${res} ${monthsArr[0]}`;
      }
      res = `${res} ${yearsArr[0]}`;
    }
    if (isPeriodic) {
      res = `${res} ${periodicStrSuffix}`
    };
  }
  return res;
}

/**
 * @function displayEventTime
 * @description function to display the event date in the proper formatted format according to the event type
 * @param {Set.<string>} distinctTimes contains distinct start and end times in the form of JSON stringified objects
 * @returns {string} formatted event time to display
 */
export const displayEventTime = (distinctTimes) => {
  return [...distinctTimes].map(it => {
    const parsed = JSON.parse(it);
    return `${parsed?.startTime} - ${parsed?.endTime}`
  }).join(' or ');
}

/**
 * @function setEventDistinctDaysAndTime
 * @description sets distinct event times and dates, in a JSON strigified object form, in their respective Sets using the respective state-setters
 * @param {string} eventType type of event
 * @param {string} reservationSlot object conatining the reservation slot information of a booking (booking.reservationSlot)
 * @param {React.Dispatch.<React.SetStateAction.<string>>} daySetter state setter for the distinct day Set
 * @param {React.Dispatch.<React.SetStateAction.<string>>} timeSetter state setter for the distinct time Set
 * @returns {void}
 */
export const setEventDistinctDaysAndTime = (eventType, reservationSlot, daySetter, timeSetter) => {
  if ([eventTypes.PERIODIC_DAILY, eventTypes.PERIODIC_WEEKLY, eventTypes.PERIODIC_MONTHLY].includes(eventType)) {
    daySetter(prevState => prevState.add(JSON.stringify(
      {
        startDate: reservationSlot?.eventAvailableDates?.[0]?.date,
        endDate: reservationSlot?.eventAvailableDates?.[0]?.checkOutDate
      }
    )).add(
      JSON.stringify(
        {
          startDate: reservationSlot?.eventAvailableDates?.[reservationSlot?.eventAvailableDates?.length - 1]?.date,
          endDate: reservationSlot?.eventAvailableDates?.[reservationSlot?.eventAvailableDates?.length - 1]?.checkOutDate
        }
      )
    ));
    timeSetter(prevState => prevState.add(JSON.stringify(
      {
        startTime: reservationSlot?.eventAvailableDates?.[0]?.timeIn,
        endTime: reservationSlot?.eventAvailableDates?.[0]?.timeOut
      }
    )).add(
      JSON.stringify(
        {
          startTime: reservationSlot?.eventAvailableDates?.[reservationSlot?.eventAvailableDates?.length - 1]?.timeIn,
          endTime: reservationSlot?.eventAvailableDates?.[reservationSlot?.eventAvailableDates?.length - 1]?.timeOut
        }
      )
    ));
  } else {
    reservationSlot?.eventAvailableDates?.forEach(it => {
      daySetter(prevState => prevState.add(JSON.stringify(
        {
          startDate: it?.date,
          endDate: it?.checkOutDate
        }
      )));
      timeSetter(prevState => prevState.add(JSON.stringify(
        {
          startTime: it?.timeIn,
          endTime: it?.timeOut
        }
      )));
    });
  }
}

/**
 * @function saveBookingSession
 * @description creates an object that is stored in the Local Storage for retrieving user booking session post redirection
 * @param {object} param0 contains parameters: resource id, excet path, reservation slot data, and step for ResourceStepper
 * @returns {void} 
 */
export const saveBookingSession = ({ resourceId, excetPath, reservationSlot, step = 1 }) => {
  let details = {
    resourceId: resourceId,
    excetPath: excetPath,
    reservationSlot: { ...reservationSlot },
    step: step
  };
  SaveLocal("bookingSession", JSON.stringify(details));
}
export const findNumberOfChildren = (rental_prop_children) => {
  const numberOfChildrenArr = rental_prop_children ? rental_prop_children?.split(',') : [];
  return numberOfChildrenArr?.length > 0 ? numberOfChildrenArr?.at(-1) : null;
}

export const findAgeOfChildren = (parent, childParam, propertyObj) => {
  const totalChildren = findNumberOfChildren(parent);
  let childrenAgeArr = [];
  if (totalChildren) {
    // run a loop
    for (let index = 1; index <= totalChildren; index++) {
      childrenAgeArr.push(propertyObj?.[`${childParam}${index}`]);
    }
  }
  return childrenAgeArr;
}