/***
 *
 * Controller class for user.
 * @file Footer.js
 * @description Footer component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import './Footer.scss';
// import Text from '../Text/index';
import { useDispatch, useSelector } from "react-redux";
import { appDefaults } from '../../config';
import { useTranslation } from 'react-i18next';
//  import ImageWrapper from '../ImageWrapper';
import { Grid, Link, Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import SiteLogo from '../SiteLogo';
//  import CustomCookieConsent from '../CustomCookieConsent';
import ReactGA from 'react-ga';
import LanguageSelector from '../LanguageSelector';
import { Navigate, history } from '../../helpers';

const Footer = ({ topMenu }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const copyrightYear = '';
  const app = useSelector(state => state.app);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const maxSize = useMediaQuery(theme.breakpoints.up(1440));
  const meetingPlace = app?.meetingPlace || {
    "logo": "",
    "name": "",
    "countryCode": "",
    "mpid": "",
    "privacyPolicy": "",
    "contactUs": ""
  };
  const footerHeight = (matches || !topMenu) ? '240px' : '312px';
  let logoPosition = 2;
  let logoMarginLeft = 0;
  if (meetingPlace?.name === 'Livat Hammersmith') {
    logoPosition = 1;
    logoMarginLeft = -1.6;
  }

  const meetingPlaceLinkSx = {
    fontWeight: "700",
    textUnderlineOffset: "2px",
    textDecorationSkipInk: "none",
  }

  const copywrite = () => {
    return (
      <>
        <Typography style={{ fontSize: 12, fontFamily: 'Noto IKEA Latin' }} >
          {`© ${copyrightYear || appDefaults?.copyright?.year}`}
        </Typography>
        <Typography style={{ fontSize: 12, fontFamily: 'Noto IKEA Latin' }}>
          {appDefaults?.copyright?.text}
        </Typography>
      </>
    )
  }

  return (
    <Box className={matches ? "footerBg" : "footerBgMobile"} height={footerHeight} width={'100%'}>
      <Grid container px={{ xs: 5, md: (maxSize ? 0 : 21) }} py={3}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }} alignItems={{ xs: 'flex-start', md: 'center' }}
        flexDirection={{ xs: 'column', md: 'row' }} flexWrap={'nowrap'} height={'100%'} maxWidth={'1440px'} mx={'auto'}>
        <Grid item order={{ xs: logoPosition, md: 1 }} mb={{ xs: 0.5, md: 0 }} ml={{ xs: logoMarginLeft, md: 0 }}>
          {
            topMenu && Object?.keys(app?.meetingPlace).length > 0 && (
              <SiteLogo className={"mplogo"} />
            )
          }
        </Grid>
        <Grid item order={3} pb={5} display={{ xs: 'block', md: 'none' }}>
          {
            copywrite()
          }
        </Grid>
        <Grid item order={{ xs: 1, md: 2 }} mb={{ xs: 1, md: 0 }}>
          <Grid container flexDirection={'column'}>
            {(!matches && topMenu) && (
              <Grid item mb={1}>
                <LanguageSelector topMenu={topMenu} />
              </Grid>)}
            {topMenu && Object?.keys(app?.meetingPlace).length > 0 && (<Grid item mb={{ xs: 4, md: 1 }}>
              <Link variant="subtitle1" sx={meetingPlaceLinkSx} color={"inherit"} component={'button'} onClick={() => { Navigate(3000, { dispatch, history }) }} rel={"noreferrer"}>
                {`${app?.meetingPlace?.name} | ${app?.country?.name}`}
              </Link>
            </Grid>)}
            {
              topMenu &&
              <>
                <Grid item mb={{ xs: 1, md: 3 }}>
                  {Object?.keys(app?.meetingPlace).length > 0 && (appDefaults?.environment === 'qa' ?
                    <ReactGA.OutboundLink
                      className={"link2 ga-link"}
                      color={'inherit'}
                      eventLabel="privacyPolicy"
                      to={meetingPlace?.privacyPolicy}
                      target="_blank"
                    >
                      {t("S_PRIVACY_POLICY")}
                    </ReactGA.OutboundLink>
                    :
                    <Link variant='body1' className={"link"} color={'inherit'} component='a'
                      href={meetingPlace?.privacyPolicy} target="_blank" rel="noreferrer">
                      {t("S_PRIVACY_POLICY")}
                    </Link>)}
                </Grid>
                <Grid item>
                  {Object?.keys(app?.meetingPlace).length > 0 && (appDefaults?.environment === 'qa' ?
                    <ReactGA.OutboundLink
                      className={"link2 ga-link"}
                      color={'inherit'}
                      eventLabel="contactUs"
                      to={meetingPlace?.contactUs}
                      target="_blank"
                    >
                      {t("S_CONTACT_US")}
                    </ReactGA.OutboundLink>
                    :
                    <Link variant='body1' className={"link"} color={'inherit'} component='a'
                      href={meetingPlace?.contactUs} target="_blank" rel="noreferrer">
                      {t("S_CONTACT_US")}
                    </Link>)}
                </Grid>
              </>}
            <Grid item display={{ xs: 'none', md: 'block' }}>
              {copywrite()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
