export const CANCELLED_STATUSES = [
    8,
    9,
    11,
    15,
    16,
    17,
    19,
    23,
    24,
    25,
    27,
    49,
    51,
    75,
    144,
    145,
    147,
    177,
    179,
    264,
    265,
    267,
    272,
    273,
    275,
    280,
    281,
    283,
    305,
    307,
    400,
    401,
    403,
    433,
    435,
    512,
    521,
    523,
    777,
    779,
    2056
];

export const PENDING_STATUSES = [
    0,
    1,
    3,
    128,
    129,
    131,
    256,
    257,
    259,
    384,
    385,
    387
];

export const CONFIRMED_STATUSES = [
    5,
    7,
    37,
    39,
    133,
    135,
    165,
    167,
    261,
    263,
    293,
    295,
    389,
    391,
    421,
    423,
];

export const PAYMENT_STATUSES = [
    0,
    128,
    256,
    384
]