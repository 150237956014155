import { bookingTabs, dataLayerEvents } from '../constants';
import { setPage } from '../redux/actions';
import routes from '../routes/routes';
import { delay } from '../services';
import { pushToDataLayer } from '../services/analytics.service';
import { GetSession } from './session.helper';


export function IsAuthenticated() {
    return true;
}


export function IsAuthorized() {
    return true;
}

function createParamString(params = []) {
    let paramString = '';
    if (params.length >= 0) {
        paramString = params.join('/');
    }
    return paramString;
}

export function createLangCountryMeetPlaceStr(language, country, meetingPlace = null, site = null) {
    let arr = [];
    arr.push(`${language?.toLowerCase() + (country ? '-' + country?.toUpperCase() : '')}`);

    if (meetingPlace) { arr.push(meetingPlace?.toLowerCase()); }
    // adding site type to url currently creating issues due to site 
    // type only being available after general booking page
    if (site) { arr.push(site?.replace(/ /g, "")?.toLowerCase()); }
    return createParamString(arr);
}

//method to Navigate,there exist pages with same pathe and differentID
//use storage to retain the page state of required
//pass route param of rout object
//pass dipatch and history hook from 

export const Navigate = (routeId, { dispatch, history, id, email, mode = 'push', excetPath, userDetails, pageReload = false, isReservationId = false, tabPanel = bookingTabs?.ACTIVE_BOOKINGS }) => {
    let routeItem = routes.find(item => item.id === routeId)
    Promise.resolve().then(() => {
        dispatch({ type: "pageLoader", payload: true });
    }).then(delay(200)).then(() => {
        const selectedLanguage = GetSession("selectedLanguage");
        const country = JSON.parse(GetSession("country") || "{}");
        const meetingPlace = GetSession('meetingPlace') && GetSession('meetingPlace') !== "undefined" && GetSession('meetingPlace') !== "{}" ? JSON.parse(GetSession("meetingPlace") || "{}") : null;
        const site = JSON.parse(GetSession("site") || "{}");
        return { selectedLanguage, country, meetingPlace, site }
    }).then(({ selectedLanguage, country, meetingPlace, site }) => {
        if (excetPath) {
            Promise.resolve().then(() => {
                let arr = excetPath.replace(/^\/|\/$/g, '').split("/");
                arr[0] = `/${selectedLanguage?.toLowerCase() + (country?.code ? '-' + country?.code?.toUpperCase() : '')}`;
                const url = arr.join("/");
                history.replace(url, { key: `${routeItem?.key}-${new Date()?.getTime()}`, page: JSON.stringify(routeItem) });
            }).then(delay(300)).then(() => {
                dispatch({ type: "pageLoader", payload: false });
            })
        } else {
            Promise.resolve().then(() => {
                let urlStr = "";
                if (routeId && routeId !== 2000 && routeId !== 1000 && routeId !== 20000 && routeId !== 30000) {
                    if (selectedLanguage && country?.code && routeItem?.key === "mb") {
                        urlStr += `/${createLangCountryMeetPlaceStr(selectedLanguage, country?.code)}`;
                    } else if (selectedLanguage && country?.code && routeId === 1001) {
                        urlStr += `/${createLangCountryMeetPlaceStr(selectedLanguage, country?.code)}`;
                    } else if (selectedLanguage && country?.code && routeItem?.key === "bk") {
                        urlStr += `/${createLangCountryMeetPlaceStr(selectedLanguage, country?.code)}`;
                    } else if (selectedLanguage && country?.code && meetingPlace?.name && routeItem?.key !== "mb") {
                        if (routeItem?.key === 'le' || routeItem?.key === 'pa' || routeItem?.key === 'mbp' || routeItem?.key === 'bd') {
                            urlStr += `/${createLangCountryMeetPlaceStr(selectedLanguage, country?.code, meetingPlace?.id)}`;
                        } else {
                            urlStr += `/${createLangCountryMeetPlaceStr(selectedLanguage, country?.code, meetingPlace?.id, (routeItem?.key === "gbp" ? null : site?.type))}`;
                        }
                    }
                } else if (routeId === 20000 || routeId === 30000) {
                    urlStr += `/${createLangCountryMeetPlaceStr(selectedLanguage, country?.code)}`;
                }
                return urlStr;
            }).then(delay(200)).then((urlStr) => {
                if (routeId !== 20000 && routeId !== 30000) {
                    urlStr += `/${routeItem?.key || ''}`;
                }
                urlStr += `${routeItem?.route}`;
                return { routeItem, urlStr };
            }).then(({ routeItem, urlStr }) => {
                if (id) { urlStr += `/${id}`; }
                if (email) { urlStr += `/${email}`; }
                return { routeItem, urlStr };
            }).then(delay(100)).then(({ routeItem, urlStr }) => {
                // mode === 'push' ? history.push(urlStr) : history.replace(urlStr);
                if (routeItem?.id) {
                    routeItem["excetPath"] = urlStr;
                    if (routeItem?.id !== 1001 && routeItem?.id !== 1000) {
                        dispatch(setPage(routeItem));
                    }
                }
                history[mode](urlStr, { key: `${routeItem?.key}-${new Date()?.getTime()}`, page: JSON.stringify(routeItem), tabPanel });
                /*
                in case of booking flow(step-1,2,3) and booking confirmed page we are not pushing dataLayer info from navigation helper
                as there are special paramters added to the pagePath in those URL's hence they have been tackeled separately.
                */
                const queryParams = JSON?.parse(GetSession('queryParams') || '{}');
                if (routeItem?.id && routeItem?.id !== 5004 && routeItem?.id !== 5006) {
                    let dataLayerObj = {
                        eventName: dataLayerEvents?.VIRTUAL_PAGE_VIEW,
                        market: `${selectedLanguage?.toLowerCase()}-${country?.code ? country?.code?.toUpperCase() : ""}`,
                        meetingPlace: meetingPlace?.name,
                        meetingPlaceId: meetingPlace?.mpid,
                        pagePath: `${urlStr}${queryParams?.paramString || ''}${queryParams?.fragment || ''}`,
                        title: `Booking Tool${meetingPlace?.name ? " | " + meetingPlace?.name : ""}`
                    }
                    pushToDataLayer(dataLayerObj);
                } else if (routeItem?.id && routeItem?.id === 5006) {
                    let dataLayerObj = {
                        eventName: dataLayerEvents?.VIRTUAL_PAGE_VIEW,
                        market: `${selectedLanguage?.toLowerCase()}-${country?.code ? country?.code?.toUpperCase() : ""}`,
                        meetingPlace: meetingPlace?.name,
                        meetingPlaceId: meetingPlace?.mpid,
                        pagePath: `${urlStr}${isReservationId ? '/comfirmed' : ''}${queryParams?.paramString || ''}${queryParams?.fragment || ''}`,
                        title: `Booking Tool${meetingPlace?.name ? " | " + meetingPlace?.name : ""}`
                    }
                    pushToDataLayer(dataLayerObj);
                }

                if (pageReload) { window.location.reload(); }
                return urlStr;
            }).then(delay(700)).then(() => {
                dispatch({ type: "pageLoader", payload: false });
            })
        }
    });
}